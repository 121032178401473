.button_chains{
    background: transparent;
    border-radius: 0.5rem;
    color: white;
    transition: all 0.8s ease-in-out;
    font-weight: 500;
    font-size: 1.4rem;
    padding: 1% 3%;
    animation: appear 0.5s ease-in-out;
    top: 20px;
    right: 50px;
}

.button_chains:hover{
    transition: all 0.3s ease-in-out;
    color: rgb(14, 158, 14);
}

@keyframes appear {
    0%{
        opacity: 0;
    }

    20%{
        opacity: 0.1;
        /* font-size: 0.2rem; */
    }

    50%{
        opacity: 0.4;
        /* font-size: 0.5rem; */

    }

    70%{
        opacity: 0.7;
        font-size: 0.9rem;

    }

    100%{
        opacity: 1;
        /* font-size: 1.4rem; */

    }
}

